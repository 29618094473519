@import '../../../../../src/shared/globalStyles/app.scss';

.card {
  border: 1px solid #ffffff75;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  text-align: center;
  width: 20%;
  font-family: 'Lato';

  &__title {
    font-size: rem(14px);
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    margin: 0;
  }

  &__subtitle {
    font-size: 14px;
    margin-top: 0;
  }

  &__img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  @include small-breakpoint {
    width: 100%;
    padding: 10px 0;
  }
}
