@import '../../shared/globalStyles/app.scss';

.selection {
  &--jumbotron {
    max-width: rem(822px);

    .jumbotron--subtitle {
      margin: rem(20px) rem(75px) 0 rem(75px);

      @include small-breakpoint {
        margin: rem(10px) 0;
      }
    }
  }
}
