@import '../../shared/globalStyles/app.scss';

.welcome-back {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $spacing-XXL;
    max-width: calc(1116px - 4rem);
    width: 80%;
    opacity: 0;
    transition: 1s opacity;
  }

  &__fade-in {
    opacity: 1;
  }

  &__header {
    & h1 {
      margin-bottom: 0rem;
    }

    &--title {
      text-align: center;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 800;
      font-size: 2rem;
      line-height: rem(38px);
    }

    &--subtitle {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2.5rem;

      & h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        @include small-breakpoint {
          text-align: center;
        }
      }
    }
  }

  &__results {
    background-color: #fff;
    height: 200px;
    width: 800px;
  }
}
