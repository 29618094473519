@import '../../shared/globalStyles/app.scss';

.icon-button {
  background-color: $dodger-blue;
  vertical-align: text-bottom;
  display: inline-block;
  border: 0px;
  border-radius: rem(62px);
  cursor: pointer;
  height: rem(124px);
  width: rem(124px);
  font-family: $font-family-base;
  font-size: rem(18px);
  font-weight: bold;
  line-height: 1.44;

  @include small-breakpoint {
    height: rem(80px);
    width: rem(80px);
    font-size: rem(12px);
    line-height: 1.2;
  }

  svg {
    fill: $white-color;
    stroke: white;

    @include small-breakpoint {
      width: rem(48px);
      height: rem(34px);
    }
  }

  &--center {
    @include center;
  }

  &--label {
    display: none;
    color: $white-color;
  }

  &--box {
    @include center-box;
    width: 100%;

    &:hover {
      .icon-button--icon {
        display: none;
      }

      .icon-button--label {
        display: block;
      }
    }
  }

  &__selected {
    background: $green-yellow;

    .icon-button--box {
      svg {
        fill: $black-color;
        stroke: black;
      }

      &:hover {
        .icon-button--label {
          color: $black-color;
        }
      }
    }
  }

  &__disabled {
    opacity: $disabled-color;
    cursor: not-allowed;
  }
}
