@import '../../../shared/globalStyles/app.scss';

/* Estilos del contenedor del gráfico */
.donut-container {
  position: relative;
  background-color: #11755e;
}

.donut-container svg {
  overflow: visible !important;
}

/* Estilos del agujero en el gráfico */
.donut-hole {
  position: absolute;
  /*top: 20%;/* 25%;*/
  /*left: 20%;/* 25%;*/
  /*width: 60%;/* 50%;*/
  /*height:60%;/* 50%;*/
  top: 34%;
  left: 13.5%;
  width: 35%;
  height: 32%;

  border-radius: 50%;
  background: #11755e; /* Color del agujero */
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @include small-breakpoint {
    width: 37%;
    height: 30%;
    top: 35%;
    left: 12.5%;
  }
}
.amount-co2e {
  color: #fff;
  text-align: center;
  font-family: 'Interstate', Helvetica, Arial, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.text-co2e {
  flex-wrap: 'nowrap';
  color: #fff;
  text-align: center;
  font-family: 'Interstate', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Estilos del texto del porcentaje*/
.percentage-text {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

/* Estilos del texto del porcentaje */
.percentage-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}

/* Estilos del gráfico de donut */
.donut-chart {
  position: relative;
  width: 1200px;
  height: 200px;
  padding-bottom: 10px;
  border-radius: 150%;
  background: #80b980;
  overflow: hidden;
}

.donut-bottom-legend {
  // margin-bottom: 50px;
  // padding-bottom: 50px;
  color: #fff;
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  @include small-breakpoint {
    padding: 0 1rem;
  }
}
//validate #TODO
.google-visualization-tooltip {
  border-radius: 10px !important;
  top: 10% !important;
  ::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 15px 1px 0;
    border-color: transparent #f0f0f0;
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
  }
}
