@import '../../shared/globalStyles/app.scss';

.checkbox-group {
  display: grid;
  grid-template-columns: 1.3fr auto 3fr;
  grid-template-rows: repeat(4, 1fr);
  grid-gap: rem(20px);
  grid-auto-flow: column;
  list-style: none;
  margin: 0;
  padding: 0;

  @include small-breakpoint {
    display: block;
    margin-top: 30px;
  }

  &--item {
    display: flex;
    flex-direction: row;

    @include small-breakpoint {
      margin-bottom: rem(10px);
    }
  }

  &--other {
    opacity: 0;
    flex-grow: 1;

    &__selected {
      opacity: 1;
      display: flex;
    }

    &-input {
      flex-grow: 1;
      border: 0;
      background-color: transparent;
      border-bottom: 1px solid $white-color;
      margin-left: rem(10px);

      color: $white-color;
      font-family: $font-family-secondary;
      font-size: rem(16px);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}
