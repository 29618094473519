@import '../../shared/globalStyles/app.scss';

.splash-screen {
  @include center;
  height: 100vh;
  overflow: hidden;

  @include small-breakpoint {
    width: 100%;
  }

  &--holder {
    @include center-box;
  }
}
