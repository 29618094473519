@import '../../shared/globalStyles/app.scss';
@import '../../shared/base/animations.scss';
@import '../../shared/globalStyles/variables.scss';

.Content {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  max-width: 328px;
  width: 80%;
  min-height: 425px;
  //eslint-disable-next-line no-sequences
  background-color: rgba(21, 132, 106, 0.5);
  overflow: 'hidden';
  border-radius: 10px;
  border-bottom: 16;
  position: absolute;
  background: transparent;
  outline: none;
  padding: 20px;
}

.modal-button {
  width: 100%;
  height: 44px;
  padding: 9px 12px 7px;
  border-radius: 22px;
  background-color: $green-yellow;
  border: none;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: $black-color;
  font-family: Interstate;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: lighten($green-yellow, 10%);
  }
}

.whiteBoard {
  height: fit-content;
  min-height: 425px;
  margin: auto;
  padding: 1rem 2rem;
  border-radius: 20px;
  background-color: $white-color;
  //visual effect
  animation-name: showUp;
  animation-duration: 0.6s;

  ///
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: baseline;

  &__title {
    // width: 10rem;
    height: 52px;
    margin-bottom: 0;
    font-family: Interstate;
    font-size: $font-size-L; //18px
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: $black-color;
    padding: 0 2rem;
  }

  &__text {
    font-family: Lato;
    font-size: $font-size-S; //14px
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: $black-color;

    &--cursive {
      font-style: italic;
      color: $black-color;
      font-size: 12px;
      text-align: center;
    }

    &--bold {
      font-weight: 900;
    }
  }
}
