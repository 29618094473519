@import '../../shared/globalStyles/app.scss';

.heading {
  &--h1 {
    font-size: rem(32px);
  }

  &--h2 {
    font-size: rem(28px);
  }

  &--h3 {
    font-size: rem(24px);
  }

  &--h4 {
    font-size: rem(20px);
    font-weight: bold;
  }
}
