@import '../../shared/globalStyles/app.scss';

.jumbotron {
  text-align: center;
  color: $white-color;

  h1 {
    font-size: rem(24px);
    font-weight: bold;

    @include small-breakpoint {
      font-size: rem(18px);
      line-height: 1.4;
    }
  }

  &--subtitle {
    font-size: rem(20px);
    margin: rem(20px) 0;

    @include small-breakpoint {
      font-size: rem(14px);
      line-height: 1.57;
    }
  }
}
