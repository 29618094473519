@import '../../shared/globalStyles/app.scss';

.radio-button {
  display: inline-block;
  border-right: 1px solid $white-color;

  @include small-breakpoint {
    flex-grow: 1;
  }

  &:last-child {
    border-right: 0;
  }

  &--input {
    opacity: 0;
    position: absolute;

    &:checked + .radio-button--label {
      background-color: $green-yellow;
      color: $black-color;
    }

    &:focus + .radio-button--label {
      outline: rgba(77, 97, 171, 0.5) auto 3px;
    }
  }

  &--label {
    color: $white-color;
    font-family: $font-family-secondary;
    font-size: rem(16px);
    display: block;
    cursor: pointer;
    height: rem(62px);
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 rem(36px);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include small-breakpoint {
      font-size: rem(12px);
      padding: 0;
      height: rem(43px);
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.71;
      background-color: $sky-blue;
    }
  }

  &--shrink {
    .radio-button--label {
      padding-left: rem(21px);
      padding-right: rem(21px);

      @include small-breakpoint {
        padding-left: rem(12px);
        padding-right: rem(12px);
      }
    }
  }

  &--legend {
    .radio-button--label {
      &[disabled] {
        cursor: default;
        opacity: inherit;
        background-color: transparent;
      }
    }
  }
}
