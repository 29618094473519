@import '../../shared/globalStyles/app.scss';

.measure {
  color: $white-color;
  font-size: 3rem;
  font-weight: 600;
  font-family: $font-family-secondary;
  width: 133px;
  height: 133px;
  padding: 0px;
  align-self: center;
  line-height: 1;
  min-width: 133px;

  &__text {
    text-align: left;
    width: 50%;
    padding: 0px 40px;
    margin: 0px;
  }

  &__value {
    display: flex;
    font-weight: 400;
    font-size: 46px;
    margin-bottom: -13px;
    text-align: center;
    justify-content: center;
    font-family: Lato;
  }

  &__valueLarge {
    font-weight: 600;
    font-size: rem(20px);
    margin-bottom: -13px;
  }

  &__summaryContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #11755e;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    align-items: center;

    .measure {
      @include small-breakpoint {
        margin-left: 0 !important;
        margin-top: 40px !important;
      }
    }

    @include small-breakpoint {
      width: 100%;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 1rem !important;
    }
  }

  &__summary {
    display: flex;
    flex-direction: row;
    border: none;

    @include small-breakpoint {
      border-right: none;
      padding-bottom: 24px;
      flex-direction: column !important;
      align-items: center;
    }
  }

  @include small-breakpoint {
    font-size: rem(50px);
  }

  &--kg-co2 {
    font-size: rem(16px);
    font-weight: 400;

    @include small-breakpoint {
      font-size: rem(20px);
      line-height: rem(28px);
    }
  }

  &__label {
    color: $white-color;
    display: flex;
    justify-content: center;
    max-width: 90%;
    align-self: center;
    line-height: 18px;
    margin: 0 45px 0 45px;
    font-weight: 400;
  }

  &--superscript {
    font-size: rem(20px);
  }

  &__emissions-change {
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #69e7ad;
    border-radius: 100%;
    width: rem(66.51px);
    min-height: rem(66.51px);
    height: rem(66.51px);
    flex-direction: column;
  }

  &__change-percentage-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5712px;
    line-height: 13px;
    text-align: center;
    margin: 5px 0 0 0;
    color: #000;
  }
}

.bubble-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4.40468px solid #fff;
  border-radius: 100px;
  width: 124.58px;
  min-width: 124.58px;
  height: 124.58px;

  &__red-border {
    border: 4.40468px solid $red;
  }
}

@mixin text-container-style {
  display: flex;
  flex-direction: column;
  bottom: 27%;
  align-items: center;
}

.text-container {
  display: flex;
  flex-direction: column;
  bottom: 27%;
  align-items: center;

  &__relative-position {
    @include text-container-style();
    position: relative;
  }

  &__inherit-position {
    @include text-container-style();
    position: inherit;
  }
}
