@import '../../../shared/globalStyles/app.scss';

.button-list {
  padding-bottom: $spacing-S;

  .MuiToggleButtonGroup-root {
    .MuiToggleButtonGroup-groupedHorizontal {
      padding: $spacing-S $spacing-L;
      color: $white-color;
      font-size: $font-size-M;
      text-transform: lowercase;
      white-space: nowrap;
      border: 1px solid $white-color;

      &:first-child {
        border-top-left-radius: $radius-31;
        border-bottom-left-radius: $radius-31;
      }
      &:last-child {
        border-top-right-radius: $radius-31;
        border-bottom-right-radius: $radius-31;
      }
    }
    .Mui-selected {
      color: $black-color;
      background-color: $green-yellow;
      @include transition(background-color 0.5s ease-in-out);
      &:hover {
        background-color: darken($green-yellow, 10%);
      }
    }
  }

  @include small-breakpoint {
    padding: $spacing-S 0;
    overflow-x: scroll;
  }
}
