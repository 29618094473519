@import '../../../shared/globalStyles/app.scss';

.option {
  margin-right: $spacing-XL !important;
}

.optionLimitedWidth {
  margin-right: $spacing-XL !important;
  width: 186px;
}

.icon {
  width: 15px;
  height: 15px;
  padding: $spacing-XXXS;
  border: 1px solid $white-color;
  border-radius: 5px;
  background-color: transparent;
}

.checkedIcon {
  padding: $spacing-XXXS;
  background-color: $green-yellow;
  border: 1px solid $green-yellow;
  border-radius: 5px;
  &::before {
    content: '';
    width: 15px;
    height: 15px;
    display: block;
    background-image: url(../../../../public/assets/check.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
