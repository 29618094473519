/* Variables
 * ===============
 *
 * All variables and most of the configuration is defined on this page. */

/* Containers */

$x-small: 720px;
$small: 800px;
$medium: 1200px;
$large: 1300px;

/* Breakpoints */

$mobile: 620px;
$tablet: $small;
$desktop: 621px;

$width: 800px;

/* Typography */
$font-size-XXXXXS: 4px;
$font-size-XXXXS: 8px;
$font-size-XXXS: 10px;
$font-size-XXS: 12px;
$font-size-XS: 13px;
$font-size-S: 14px;
$font-size-M: 16px;
$font-size-L: 18px;
$font-size-XL: 20px;
$font-size-XXL: 24px;
$font-size-XXXL: 32px;
$font-size-XXXXL: 40px;

$font-size-base: 1rem;
$body-font-size: 1.15rem;
$font-family-base: 'Interstate', Helvetica, Arial, sans-serif;
$font-family-secondary: 'Lato', Helvetica, Arial, sans-serif;

$font-style: normal;
$font-variant: normal;
$font-weight: normal;
$font-color: #454545;
$light-font-color: rgba(0, 0, 0, 0.6);
$line-height: 1.6;

/* Margins */

$margin-small: 16px;

/* Colors */

$white-color: #ffffff !default;
$black-color: #0d0d0d !default;
$link-color: #ffffff !default;
$green-yellow: #86ffbd !default;
$corn-flower-blue: #15846a !default;
$dodger-blue: #005d47 !default;
$royal-blue: #4777d6 !default;
$light-dodger-blue: #119f7e !default;
$sky-blue: #119f7e !default;
$light-sky-blue: #11755e !default;
$bright-gray: #e7f1ef !default;
$red: #ff9696;

$disabled-color: 0.5;

/*
  SPACINGS
  Standard spacings to be used in margins and paddings across the entire app
*/
$spacing-XXXS: 4px;
$spacing-XXS: 8px;
$spacing-XS: 16px;
$spacing-S: 20px;
$spacing-M: 24px;
$spacing-L: 32px;
$spacing-XL: 36px;
$spacing-XXL: 40px;

/*
  BORDER RADIUS
  Standard border radiuses to use in every border-radius across the app
*/
$radius-circle: 50%;
$radius-31: 31px;
$radius-32: 32px;
$radius-33: 33px;
$radius-34: 34px;
$radius-35: 35px;
$radius-36: 36px;
$radius-37: 37px;

:export {
  greenYellow: $green-yellow;
  lightSkyBlue: $light-sky-blue;
  red: $red;
  greenDark: $light-sky-blue;
}
