@import '../../shared/globalStyles/app.scss';

.centering {
  @include center;
  height: 100vh;

  @include small-breakpoint {
    width: 100%;
  }

  &--box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 1;

    @include small-breakpoint {
      padding: 0 rem(24px);
      align-self: center;
      // margin: auto;
      overflow: auto;
    }
  }
}
