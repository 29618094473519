@import '../../shared/globalStyles/app.scss';

.result {
  height: auto !important;

  &__modal {
    display: flex;
    border: none;
    margin: 0.5em;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    text-decoration: none;
    position: absolute;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $spacing-XXL;
    max-width: calc(1116px - 4rem);
    width: 80%;
    opacity: 0;
    transition: 1s opacity;
  }

  &__fade-in {
    opacity: 1;
  }

  &__heading-container {
    display: flex;
    width: 100%;
    max-width: 1116px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
  }

  &__title {
    text-align: start;
    font-family: $font-family-secondary;
    font-weight: 900;
    font-size: 32px;
    margin: 0;
  }

  &__subtitle {
    text-align: start;
    font-family: $font-family-secondary;
    font-weight: 400;
    font-size: 18px;
    display: -webkit-inline-flex;
  }

  &__value {
    display: flex;
    justify-content: center;
    font-size: rem(14px);
    flex-direction: column;
    @include small-breakpoint {
      padding-top: 1rem;
    }
  }

  &__emissions-label2 {
    margin-top: 20px;
  }

  &__emissions-label3 {
    margin-top: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  &__description {
    text-align: center;
    font-size: $spacing-M;
    margin: 0px;
    font-size: 18px;
    margin-left: 20px;
    margin-bottom: 5px;
  }

  &__equivalences {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    gap: 1rem;
  }
  &__summary {
    //  display: flex;
    // flex-direction: row;
    // display: grid;
    // grid-template-columns: auto 1fr; /* La segunda fila se expandirá para llenar el espacio restante */
    //grid-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Esto establece la misma anchura para ambas columnas */
    grid-template-rows: 1fr;
    ///
    ///
    gap: 2rem;
    // max-width: 1116px;
    // height: fit-content;
    // border-radius: 1rem;
    // align-items: center;
    margin-bottom: 2rem;
    position: relative;
    @include small-breakpoint {
      // flex-direction: column;
      grid-template-columns: 300px;
      grid-template-rows: 1fr 1fr;
    }
  }
  &__equivalences_summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 67%;
    justify-content: center;
    margin-right: 8px;

    @include small-breakpoint {
      width: 100%;
      align-items: center;
    }
  }

  &__banner-left {
    display: flex;
    align-items: center;
    background-color: #11755e;
    width: 100%;
    border-radius: 1rem;
    //padding: calc(35px);
    flex-direction: column;
    //margin-right: 30px;

    // here recognize
    .emission__charts {
      height: auto;
      display: flex;
      flex-direction: column;
      width: inherit;
    }
  }

  & .banner-right-wrapper {
    background-color: #11755e;
    border-radius: 1rem;
  }

  &__banner-right {
    display: flex;
    align-items: center;
    //  padding: calc(35px);
    background-color: #11755e;
    border-radius: 1rem;
    width: 100%;
    align-self: baseline;
    justify-content: center;
    flex-direction: column;
  }

  &__banner-left-note-message {
    display: flex;
    align-items: center;
    // padding: calc(35px);
    background-color: #119f7e;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    // width: 100%;
    // box-sizing: border-box;
    // position: absolute; /* Establece la posición del zócalo como absoluta */
    bottom: 0; /* Coloca el zócalo en la parte inferior */

    padding: 1rem;
    max-width: inherit;

    @include small-breakpoint {
      flex-direction: column;
    }

    &__description-text-container {
      padding-left: 1rem;

      &__title {
        color: #fff;
        font-family: 'Lato';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0;
      }
      &__description {
        color: #fff;
        font-family: 'Lato';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  &__charts {
    padding: 1rem; //calc(35px);

    &__emissions-comparison {
      text {
        font-size: small;
        
      }
      @include small-breakpoint {
        width: 300px !important;
        font-size: x-small;
      }
    }
    &__emissions-comparison svg {
      @include small-breakpoint {
        // width: 67%;
        height: 100%;

        left: 12.5%;
        overflow: hidden;

        g:has(text) {
          display: flex;
          justify-content: flex-start;
        }
      }
    }
    &__emissions-comparison div :first-of-type(div) {
      @include small-breakpoint {
        height: 200px;
        width: 200px !important;
      }
    }
    &__emissions-per-period {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__title {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 0;
      padding-top: 34px;
    }

    &__total-emissions {
      display: flex;
      // margin:auto
      position: relative;
      // margin-left: 15%;
    }
  }

  &__banner-right {
    // display: flex;
    // align-items: center;
    // //padding: calc(35px);
    // // background-color: #119f7e;
    // border-top-right-radius: 1rem;
    // border-bottom-right-radius: 1rem;
    // width: 100%;

    &__description-text-container {
      margin: 0 rem(24.27px) 0 rem(24.27px);
    }

    &__icon-container {
      min-width: rem(49px);
    }

    &__title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
    }

    &__description {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__card {
    width: 220px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: #15846a;
    margin-top: 15px;
    padding: 1rem;

    @include small-breakpoint {
      width: 264px;
      height: 111px;
      margin-top: 0px;
    }

    &-img {
      width: 4rem;
      margin-bottom: 1rem;
    }

    &-text {
      font-size: rem($font-size-XS);
      line-height: 1.25;
      text-align: center;
      font-weight: 900;
      font-size: 18px;
      margin: 0;

      @include breakText;
    }
  }

  &__actions-container {
    background-color: #11755e;
    display: flex;
    width: 100%;
    max-width: calc(1116px - 4rem); // 1116px - padding
    height: fit-content;
    gap: 2rem;
    padding: 4rem;
    border-radius: 1rem;
    margin-bottom: 4rem;
    box-sizing: border-box;

    @include small-breakpoint {
      flex-direction: column;
      padding: 1.5rem;

      &-item {
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }

    &-question {
      display: flex;
      flex-direction: column;
      flex: 2;

      & h3 {
        font-family: $font-family-secondary;
        font-size: 1.5rem;
        font-weight: 800;
        line-height: 1.75rem;
      }

      & p {
        font-family: $font-family-secondary;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 0;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex: 5;
      gap: 2rem;
    }

    &-item {
      display: flex;
      align-items: flex-start;

      & svg {
        width: 45px;
        min-width: 45px;
        height: auto;
        fill: transparent;
        margin-right: $spacing-M;
      }

      & h4 {
        font-family: $font-family-secondary;
        font-weight: 800;
        margin: -0.25rem 0;
      }

      & p {
        font-family: $font-family-secondary;
        font-size: 14px;
        margin: 0;

        & a {
          color: $white-color;
          font-weight: 800;
          transition: color 0.2s ease-in-out;
        }
      }
    }
  }

  .footer {
    display: none;
  }

  &__general {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
    width: 100%;

    &-button {
      margin-bottom: $spacing-S;

      @include small-breakpoint {
        width: 100%;
      }
    }

    &-link {
      color: $white-color;
    }
    &-thankyou {
      background: #11755e;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      box-sizing: border-box;
      //max-width: 732px;
      height: fit-content;
      padding: 2rem;
      border-radius: 15px;
      margin-bottom: $spacing-S;
    }
    &-thankyou-text {
      margin: 0;
      padding-bottom: 5px;
    }
  }

  @include small-breakpoint {
    &__subtitle {
      margin-bottom: $spacing-XXL;
      font-size: rem($font-size-XXL);
    }

    &__description {
      text-align: left;
      @include small-breakpoint {
        text-align: center;
        margin-bottom: 16px;
      }
    }

    &__equivalences {
      flex-direction: column;
      align-items: center;
    }

    &__card {
      margin-right: 0;
      margin-bottom: $spacing-S;
    }
  }

  &--heading {
    font-size: rem(45px);
    margin-bottom: rem(60px);

    @include small-breakpoint {
      text-align: center;
      margin-bottom: rem(50px);
    }

    &__no-emissions {
      margin-bottom: 0;
      color: $green-yellow;
      font-weight: bold;
      line-height: 1.16;
    }

    @include small-breakpoint {
      font-size: rem(24px);
      line-height: rem(28px);
      margin-bottom: 18px;

      &__no-emissions {
        margin-bottom: 0;
      }
    }
  }

  &--paragraph {
    font-size: rem(20px);
    text-align: center;
    line-height: 1.4;
    margin: 0;
    max-width: rem(560px);

    &__no-emissions {
      font-family: $font-family-base;
      font-size: rem(26px);
      font-weight: bold;
      line-height: 2.23;
      max-width: inherit;
    }

    @include small-breakpoint {
      font-size: rem(14px);
      line-height: rem(22px);
      margin-top: rem(34px);

      &__no-emissions {
        margin-top: rem(10px);
      }
    }
  }
}

.digital_products {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
  }

  &__container {
    display: flex;
    gap: 2rem;
    margin-bottom: 96px;

    @include small-breakpoint {
      flex-direction: column;
      align-items: center;
    }
  }
}
