/* Mixins
  =============== */

// Media query for mobile first layout
@mixin small-breakpoint {
  @media (max-width: #{$mobile}) {
    @content;
  }
}

// Break on desktop
@mixin large-breakpoint {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

// centering

@mixin center {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@mixin center-box {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 1;
}

// Transition with multi args
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

// Break line text
@mixin breakText {
  white-space: normal;
  word-break: break-word;
  word-wrap: break-word;
}

// main container

@mixin main-cointaner {
  width: rem(850px);

  @include small-breakpoint {
    width: 100%;
  }
}

@mixin vehicles {
  &--heading {
    margin-bottom: rem(36px);

    @include small-breakpoint {
      margin-bottom: rem(14px);
      font-size: rem(16px);
    }
  }

  &--vehicle-type {
    margin: 0 0 rem(60px);

    @include small-breakpoint {
      margin-bottom: rem(40px);
    }
  }

  &--distance-heading {
    display: flex;
    align-items: center;

    &-subtitle {
      font-family: $font-family-secondary;
      font-size: rem(16px);
      font-weight: normal;
      margin-left: rem(12px);

      @include small-breakpoint {
        display: block;
        margin-left: 0;
      }
    }
  }

  &--emissions-config {
    list-style: none;
    padding: 0;
    margin: 0;

    &__disable {
      display: none;
    }
  }

  &--emissions-item {
    margin-bottom: rem(80px);

    @include small-breakpoint {
      margin-bottom: rem(40px);
    }
  }

  &--description-text {
    margin: 0 0 rem(18px);

    @include small-breakpoint {
      font-size: rem(14px);
    }
  }
}
