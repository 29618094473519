@import '../../shared/globalStyles/app.scss';

.breadcrumb {
  display: inline-flex;
  flex-direction: row;
  border-radius: 146px;
  background-color: $light-sky-blue;
  flex-wrap: nowrap;
  list-style: none;
  height: rem(78px);
  padding: 0;
  margin: 0 0 rem(45px);
  justify-content: flex-start;

  @include small-breakpoint {
    height: rem(54px);
    min-width: rem(54px);
    max-width: 100%;
  }
}
