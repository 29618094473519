@import '../../../../shared/globalStyles/app.scss';

.eco-friendly {
  @include large-breakpoint {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @include center-box;
  }

  @include small-breakpoint {
    margin: rem(40px) 0 rem(20px);
  }

  &--heading {
    font-weight: bold;
    line-height: 1.31;
    text-align: center;
    margin: 0 0 rem(58px);

    @include small-breakpoint {
      font-size: rem(18px);
    }
  }
}
