/* Scaffolding
  =============== */
// HTML base
html {
  font: $font-style $font-variant $font-weight #{$font-size-base}/#{$line-height} $font-family-secondary;
  font-family: $font-family-secondary;
  font-size: 100% !important;
}

html,
body {
  height: 100%;
}

// Body base
body {
  background-color: $corn-flower-blue;
  color: $white-color;
  font-size: $font-size-base;
  margin: 0;
  overflow-x: hidden;
}

:root {
  --green-yellow: #86ffbd;
  --green-dark: #11755e;
  --red: #ff9696;
}

h1,
h2,
h3,
h4 {
  margin: 0 0 1rem 0;
  font-family: $font-family-base;
  line-height: $line-height;
  font-weight: normal;
  color: $white-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // &:not(:first-child) {
  //   margin: 0 0 1rem 0;
  // }
}

p {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
