@import '../../shared/globalStyles/app.scss';

.header {
  display: flex;
  padding: rem(35px) rem(35px) rem(40px) rem(35px);
  flex-wrap: nowrap;
  justify-content: space-between;

  @include small-breakpoint {
    flex-wrap: wrap;
    margin: rem(22px);
    padding: 0;
    flex-direction: column;
    position: relative;
  }

  &__left {
    display: flex;
    flex-wrap: nowrap;

    @include small-breakpoint {
      display: block;
    }

    svg {
      margin-right: rem(46px);
      height: auto;
      width: rem(116px);

      @include small-breakpoint {
        height: auto;
        width: rem(81px);
        margin: 0;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;

    @include small-breakpoint {
      position: absolute;
      right: 0;
    }

    svg {
      @include small-breakpoint {
        height: auto;
        width: rem(90px);
        margin: 0;
      }
    }
  }

  &__menu {
    align-items: flex-end;
    display: flex;
    padding-bottom: 4px;

    @include small-breakpoint {
      margin-top: rem(14px);
      justify-content: center;
      padding-bottom: 0;
    }
  }

  &__myfootprint {
    width: rem(166px);
    margin-right: rem(32px);
  }
}
