@import '../../../shared/globalStyles/app.scss';

.breadcrumb-item {
  display: flex;
  width: rem(78px);
  height: 100%;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  @include small-breakpoint {
    width: rem(54px);
    min-width: rem(48px);
  }

  &--active {
    background-color: $dodger-blue;
  }

  svg {
    fill: $white-color;
    stroke: $white-color;
    height: rem(28px);
    width: rem(42px);

    @include small-breakpoint {
      width: rem(30px);
    }
  }
}
