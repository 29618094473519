@import '../../shared/globalStyles/app.scss';

.footer {
  display: flex;
  padding: rem(60px) rem(40px);
  height: rem(60px);
  position: relative;
  z-index: 1;

  @include small-breakpoint {
    padding: 0 rem(15px) rem(24px) rem(15px);
  }

  &--left {
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: rem(20px);
  }

  &--center {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &--right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: rem(20px);

    @include small-breakpoint {
      flex: 2;
    }
  }

  &--left-button {
    background: none;
    color: $white-color;

    @include small-breakpoint {
      padding: rem(8px) rem(10px);
    }
  }

  &--map {
    background-image: url('../../../public/assets/map.png');
    background-repeat: no-repeat;
    background-size: rem(437px) rem(568px);
    width: rem(437px);
    height: rem(568px);
    position: fixed;
    z-index: -1;
    bottom: 0;
    left: 0;
    @include small-breakpoint {
      //display: none;
    }
  }

  p {
    font-family: $font-family-secondary;
    font-size: rem(16px);
    line-height: 1.4;
    margin: 0 0 rem(6px);

    @include small-breakpoint {
      font-size: rem(12px);
      text-align: center;
    }
  }

  a {
    color: $white-color;
  }

  &--terms {
    max-width: rem(800px);
    text-align: center;

    p {
      font-family: $font-family-secondary;
      font-size: rem(14px);
      line-height: 1.6;
      margin: 0 0 rem(6px);

      @include small-breakpoint {
        font-size: rem(12px);
        text-align: center;
      }
    }
    a {
      color: $white-color;
    }
  }
}
