@import '../../shared/globalStyles/app.scss';

.page404 {
  &--icon {
    margin-bottom: rem(37px);
  }

  &--content {
    margin-bottom: rem(20px);

    @include small-breakpoint {
      margin-bottom: rem(56px);
    }
  }
}
