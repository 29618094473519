@import '../../shared/globalStyles/app.scss';

.login {
  &__title {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 1rem;
    font-family: $font-family-secondary;
    text-align: center;
    line-height: 28px;
  }

  &--jumbotron {
    max-width: rem(720px);
    margin-bottom: rem(29px);

    @include small-breakpoint {
      padding: 0 rem(22px);
    }
  }

  &--centering {
    opacity: 0;
    transition: 1s opacity;

    &__fade-in {
      opacity: 1;
    }
  }

  .loading-spinner {
    background-color: #ffffff;
  }
}
