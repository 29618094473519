@import '../../shared/globalStyles/app.scss';

.home-consumption {
  &__centering .centering--box {
    display: table !important;
  }

  &__page {
    @include large-breakpoint {
      width: 100%;
      height: 100%;
      display: table-cell;
    }
    @include small-breakpoint {
      width: auto;
      margin: rem(20px) 0 rem(20px) rem(20px);
    }
  }

  &__breadcrumb-zone {
    @include main-cointaner;
    margin: 0 auto;
  }

  &__breadcrumb-item {
    // padding: 5px !important;
  }

  &__breadcrumb-item svg {
    width: auto !important;
    height: auto !important;
  }

  &__sections {
    width: 100%;

    .home-office-section,
    .devices-energy-section,
    .house-cooling-section,
    .house-heating-section,
    .electricity-use-section {
      &__content {
        width: 100%;
        position: relative;
        opacity: 0;
        transition: 1s opacity;
      }

      &__fade-in {
        opacity: 1;
      }

      &__sub-content {
        background-color: transparentize($color: $dodger-blue, $amount: 0.6);
      }

      &__title {
        @include main-cointaner;
        margin: 0 auto;
      }

      &__description {
        @include main-cointaner;
        margin: 0 auto;
      }

      &__form {
        display: flex;
        flex-direction: column;
      }

      &__footer {
        margin-top: $spacing-XXL;
      }

      &__thumb {
        width: 60px !important;
        height: 60px !important;
        box-shadow: none !important;
      }

      &__thumb-value {
        font-size: $font-size-XL;
        font-weight: bold;
      }

      &__thumb-img {
        width: 100%;
        height: 100%;
        margin-top: $spacing-XS;
        object-fit: fill;
      }

      @include small-breakpoint {
        &__sub-content {
          background-color: transparent;
        }
      }
    }
  }

  @include small-breakpoint {
    &__centering .centering--box {
      table-layout: fixed;
      width: 95%;
    }
  }
}
