@import '../../shared/globalStyles/app.scss';

.checkbox {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  &--button {
    background-color: transparent;
    vertical-align: text-bottom;
    display: inline-block;
    margin-right: rem(16px);
    border-radius: rem(2px);
    height: rem(20px);
    width: rem(20px);
    border: solid 1px $white-color;
  }

  &--label {
    font-family: $font-family-secondary;
    font-size: rem(16px);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include small-breakpoint {
      font-size: rem(15px);
    }
  }

  svg {
    margin-top: 4px;
  }

  &--center {
    @include center;
  }

  &--checked {
    display: none;
  }

  &--box {
    @include center-box;
    width: 100%;
  }

  &__selected {
    .checkbox--button {
      background: $green-yellow;
      border-color: $green-yellow;
    }

    .checkbox--box {
      .checkbox--icon {
        display: none;
      }

      .checkbox--checked {
        display: block;
      }
    }
  }
}
