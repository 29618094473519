@import '../../../shared/globalStyles/app.scss';

.question {
  @include main-cointaner;
  display: flex;
  flex-direction: column;
  margin: $spacing-L auto;

  &__title {
    display: inline-block;
  }

  &__observation {
    margin-left: $spacing-XS;
    font-size: $font-size-M;
    font-weight: 100;
  }

  &__description {
    margin: 0;
  }

  &__options {
    margin-top: $spacing-M;
  }

  @include small-breakpoint {
    &__title {
      margin-right: $spacing-XS;
    }

    &__description {
      margin-right: $spacing-XS;
    }
  }
}
