@import '../../shared/globalStyles/app.scss';

.dropdown {
  // height: rem(60px);

  fieldset {
    border: 2px solid $white-color;
    border-radius: 10px;
  }

  input {
    color: $white-color;
    font-family: $font-family-secondary;
    font-size: rem(20px);
  }

  &--listbox {
    font-family: $font-family-secondary;
    max-height: 5vh;

    .MuiAutocomplete-option[data-focus='true'] {
      color: $white-color;
      background-color: $dodger-blue;
    }
  }

  .MuiOutlinedInput-root {
    &.Mui-focused {
      fieldset {
        border-width: 2px;
        border-color: $white-color;
      }
    }

    &:hover fieldset {
      border-color: $white-color;
    }
  }

  .MuiAutocomplete {
    &-endAdornment {
      right: rem(15px) !important;

      button {
        color: $white-color;
      }
    }

    &-clearIndicator {
      color: $white-color;
    }
  }

  .MuiIconButton-label,
  .MuiTouchRipple-root {
    height: rem(22px);
  }

  @include small-breakpoint {
    height: rem(48px);

    &--listbox,
    input {
      font-size: rem(16px);
    }
  }
}
