@import '../../shared/globalStyles/app.scss';

.address-not-found {
  &--icon {
    width: rem(74px);
    margin-bottom: rem(37px);
  }

  &--jumbotron {
    margin-bottom: rem(39px);

    @include small-breakpoint {
      margin: 0 rem(25px);
    }
  }
}
