@import '../../shared/globalStyles/app.scss';

$rail-height: 50px;
$icon-width: 54px;
$thumb-size: $icon-width * 0.9;

.slider {
  &-wrapper {
    width: calc(100% - 20px);
    margin-top: rem(45px);

    @include small-breakpoint {
      width: calc(100% - 58px);
    }

    .MuiSlider {
      &-root {
        height: auto;
        color: $green-yellow;
        display: flex;
        align-items: center;
        margin-right: $icon-width;
      }

      &-track,
      &-rail {
        height: 10px;
        border-radius: 5px;
      }

      &-thumb {
        width: $thumb-size;
        height: $thumb-size;
        background-color: transparent;
        margin-top: $rail-height / -2;
        margin-left: $rail-height / -2;

        &:focus,
        &:hover,
        &:active {
          box-shadow: inherit;
        }

        img {
          width: 100%;
        }
      }

      &-mark {
        width: $spacing-XXXS;
        height: $spacing-XXXS;
        border-radius: 5px;
        background-color: $corn-flower-blue;
      }

      &-markLabel {
        transform: rotateZ(-90deg);
      }
    }
  }

  &-value {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: rem(-30px);
    display: block;
    min-width: rem(135px);
    margin-bottom: $font-size-base * 1.5;
    text-align: center;
    box-sizing: border-box;
    font-family: $font-family-secondary;
    color: $white-color;
    font-size: rem(16px);
  }

  &-divider {
    display: flex;
    align-items: center;
  }

  &-icon {
    width: $icon-width;
    margin-right: rem($spacing-M);
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }

  &-percentages {
    font-family: $font-family-base;
    font-weight: bold;
    opacity: 0.5;
    font-size: 32px;

    &-start {
      margin-right: 25px;
    }

    &-end {
      margin-left: 25px;
    }
  }

  &-container {
    flex: 1;
  }
}

@include small-breakpoint {
  $icon-width: 30px;
  $thumb-size: $icon-width * 1.1;

  .slider {
    &-wrapper {
      .MuiSlider {
        &-thumb {
          width: $thumb-size;
          height: $thumb-size;
          margin-left: $thumb-size / -2;
        }
      }
    }

    &-icon {
      width: $icon-width;
      margin-right: rem(21px);
    }
  }

  &-value {
    visibility: hidden;
  }
}
