@import '../../shared/globalStyles/app.scss';

.trips {
  display: flex;
  flex-direction: column;

  &__ecoFriendly {
    height: 100vh;

    @include small-breakpoint {
      height: auto;
    }

    .trips--breadcrumb {
      margin: 0;
    }
  }

  &--holder {
    @include large-breakpoint {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      flex-grow: 1;
    }

    @include small-breakpoint {
      width: 100%;
      align-items: flex-start;
    }

    &__second-trip {
      background: $royal-blue;
      padding: rem(32px) 0 rem(62px);
      margin-top: rem(40px);
    }
  }

  &--section {
    @include large-breakpoint {
      @include main-cointaner;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
    }
    @include small-breakpoint {
      width: auto;
      padding: 0 rem(12px);
      margin: rem(20px) 0;
    }
  }

  &--breadcrumb {
    @include small-breakpoint {
    }
  }

  &--heading {
    font-weight: bold;
    line-height: 1;
    margin-bottom: rem(45px);

    @include small-breakpoint {
      margin-bottom: rem(22px);
      font-size: rem(20px);
    }
  }

  &--ecoHeading {
    font-weight: bold;
    line-height: 1;
    margin-top: rem(20px);
  }

  &--add-trip-button {
    background: transparent;
    color: $white-color;
    padding: 0;
    margin-bottom: rem(80px);

    @include small-breakpoint {
      margin-bottom: rem(45px);
    }
  }

  &--second-trip-heading {
    font-weight: bold;

    @include small-breakpoint {
      font-size: rem(20px);
    }
  }

  &--second-trip-delete-button {
    background: transparent;
    color: $white-color;
    margin-left: rem(10px);
    padding-right: rem(10px);
    padding-left: rem(10px);
  }

  &--second-trip-footer {
    background: $royal-blue;
  }
}

//       overflow-x: scroll;
//       overflow: -moz-scrollbars-none;
//       -ms-overflow-style: none;
