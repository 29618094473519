@import '../../../../src/shared/globalStyles/app.scss';
@import '../../../../src/shared/base/animations.scss';
@import '../../../../src/shared/main.scss';

.buttonInfoIcon {
  text-decoration: none;
  border: none;
  margin: 0.5em;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  cursor: pointer;

  &:hover .tooltip {
    display: block;
  }

  & .tooltip {
    display: none;
    background-color: #3d3d3d;
    color: #fff;
    width: 212px;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    bottom: 150%;
    left: 50%;
    z-index: 1;
    margin-left: -106px;
    font-family: 'Lato';
    font-weight: 700;
    line-height: 1rem;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      border-width: 5px;
      border-style: solid;
      border-color: #3d3d3d transparent transparent transparent;
    }
  }
}

.InfoModal__Content {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 750px;
  height: fit-content;
  //eslint-disable-next-line no-sequences
  background: rgba(21, 132, 106, 1);
  overflow: 'hidden';
  border-radius: 10px;
  border-bottom: 16;
  position: absolute;
  // border: 1px solid #ccc;
  background: transparent;
  outline: none;
  padding: 20px;

  &__modal-button {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    color: rgba(21, 132, 106, 1);
    background-color: #fff;
    border-radius: 22px;
    border: none;
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: darken(#fff, 10%);
    }
  }

  &__whiteBoard {
    display: flex;
    align-items: center;
    height: fit-content;
    padding: 4rem 0 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    border-radius: 20px;
    background-color: rgba(21, 132, 106, 1);
    animation-name: showUp;
    animation-duration: 0.6s;

    @include small-breakpoint {
      padding: 2.5rem 0 0.5rem;
    }

    &--container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0 5.5rem 0 6rem;

      @include small-breakpoint {
        padding: 0 2rem 0 2.5rem;
      }

      .co2icon {
        width: 5rem;
        height: auto;
        margin-bottom: 0.5rem;
      }

      &__title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 2rem;
        line-height: 2rem;

        @include small-breakpoint {
          font-size: 1rem;
          line-height: 1rem;
        }
      }

      &__text {
        margin-top: 1rem;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      &__footer {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
