@import '../../shared/globalStyles/app.scss';

.loading {
  &--title {
    margin-top: rem(25px);

    @include small-breakpoint {
      margin-top: rem(21px);
    }
  }
}
