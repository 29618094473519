@import '../../../../shared/globalStyles/app.scss';

.car {
  @include vehicles;

  &--no-emissions {
    margin: rem(60px) 0 rem(90px);

    @include small-breakpoint {
      margin-bottom: 0;
    }

    &--heading {
      font-weight: bold;
      line-height: 1.31;
      text-align: center;
      margin: 0 0 rem(58px);

      @include small-breakpoint {
        font-size: rem(18px);
      }
    }

    &--sub-heading {
      margin-bottom: rem(36px);
    }
  }
}
