@import '../../shared/globalStyles/app.scss';

.button {
  background-color: $green-yellow;
  border-radius: rem(22px);
  color: $black-color;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-family: $font-family-base;
  font-size: rem(18px);
  line-height: 1.56;
  border: 0;
  padding: rem(8px) rem(40px);
  position: relative;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include small-breakpoint {
    font-size: rem(14px);
    padding: rem(8px) rem(16px);
  }

  &:hover,
  &--disabled {
    opacity: $disabled-color;
  }

  &--disabled {
    cursor: not-allowed;
  }
}

.spinner {
  text-align: center;
  position: absolute;
  right: 13px;
  top: 5px;
}

.spinner > div {
  width: 5px;
  height: 5px;
  background-color: $black-color;
  margin: 0 1px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
