@import '../../../../shared/globalStyles/app.scss';

.welcome-back-wrapper {
  display: flex;
  border-radius: 2rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  flex-direction: row;
  overflow: hidden;
  margin-bottom: 4rem;
  @include small-breakpoint {
    flex-direction: column;
  }
}
.welcome-back {
  &__banner-left {
    display: flex;
    align-items: center;
    background-color: #11755e;
    padding: calc(29px);
    max-width: min-content;
    min-width: 36%;
    @include small-breakpoint {
      max-width: max-content;
    }
  }

  &__banner-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: calc(29px);
    background-color: #119f7e;
    width: 100%;
    text-align: center;

    @include small-breakpoint {
      width: auto;
    }

    &__description-text-container {
      margin: 0 rem(24.27px) 0 rem(24.27px);
    }

    &__icon-container {
      min-width: rem(49px);
    }

    &__title {
      font-family: 'Lato';
      color: #86ffbd;
      font-style: normal;
      font-weight: 800;
      font-size: 22px;
      line-height: 26.4px;
      margin: 0;
    }

    &__description {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    &__subtitle {
      color: #e7f1ef;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: normal;
      text-align: center;
      margin-bottom: 28px;
      margin-top: 0;
    }
    & .button {
      color: #000;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
