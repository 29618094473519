@import '../../../../../src/shared/globalStyles/app.scss';

.equivalence {
  margin-bottom: 4rem;

  &__title {
    font-size: rem(24px);
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    @include small-breakpoint {
      text-align: center;
    }

    @include small-breakpoint {
      font-size: rem(18px);
      line-height: 1.4;
    }
  }
  &__subtitle {
    font-size: rem(16px);
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @include small-breakpoint {
      text-align: center;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 1rem 0;
    gap: 1rem;

    @include small-breakpoint {
      flex-direction: column;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__link {
    text-decoration: none;
    color: #86ffbd;
    font-family: 'Lato';
    font-weight: 800;
    font-size: rem(12px);
    font-style: italic;
  }
}
