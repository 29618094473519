@import '../../shared/globalStyles/app.scss';

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;

  &-item {
    margin-right: rem(20px);
    font-family: $font-family-secondary;
    font-size: rem(12px);
    position: relative;
  }

  &-link {
    color: $white-color;
    text-decoration: none;
    opacity: 0.4;

    &.active {
      opacity: 1;

      &:after {
        content: '';
        border-bottom: 1px solid $white-color;
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
      }
    }
  }
}
