@import '../../shared/globalStyles/app.scss';

.country-selection {
  &--wrapper {
    opacity: 0;
    transition: 1s opacity;
  }

  &__fade-in {
    opacity: 1;
  }

  &--jumbotron {
    margin-bottom: rem(55px);
  }

  &--dropdown {
    margin: 0 rem(40px);
    padding-bottom: rem(32px);

    @include small-breakpoint {
      margin: 0;
    }
  }

  @include small-breakpoint {
    &--jumbotron {
      margin-bottom: rem(35px);
    }
  }
}
