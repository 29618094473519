/*! BeKind - Globers Footprint
  by Globant Team */
// Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@font-face {
  font-family: 'Interstate';
  src: url('../../public/fonts/interstate-regular-webfont.woff2') format('woff2'),
    url('../../public/fonts/interstate-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Configuration
@import 'globalStyles/variables';
@import 'globalStyles/mixins';
@import 'globalStyles/functions';

// Reset
@import-normalize;

// Initialization
@import 'base/scaffolding';
