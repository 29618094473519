@import '../../shared/globalStyles/app.scss';

.radio-buttons-group {
  border-radius: $radius-37;
  border: solid 1px $white-color;
  display: inline-block;
  overflow: hidden;

  @include small-breakpoint {
    display: flex;
    overflow-x: auto;
  }
}
