@import '../../shared/globalStyles/app.scss';

.selection-section {
  width: rem(772px);
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: rem(16px) 0 0 0;

  @include small-breakpoint {
    margin: rem(10px) auto rem(30px);
    max-width: rem(307px);
  }

  &--item {
    margin: rem(15px);

    @include small-breakpoint {
      margin: rem(11px);
    }
  }
}
